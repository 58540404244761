import axios from "axios";

const defaultPostOptions = {
  method: "POST",
  maxBodyLength: Infinity,
  headers: {
    "Content-Type": "application/json",
  },
};

const computeCriticityFromConsequence = (context, event) => {
  try {
    const currentConsequenceType = context?.changeSet?.consequence;
    if (
      currentConsequenceType &&
      !isNaN(currentConsequenceType.forced_severity) &&
      currentConsequenceType.forced_severity !== null &&
      !isNaN(currentConsequenceType.forced_probability) &&
      currentConsequenceType.forced_probability !== null
    ) {
      const options = {
        ...defaultPostOptions,
        headers: {
          ...defaultPostOptions.headers,
          Authorization: `Bearer ${context.authentication.token}`,
        },
        data: JSON.stringify({
          severity: currentConsequenceType.forced_severity,
          probability: currentConsequenceType.forced_probability,
          consequence_type: currentConsequenceType.name,
        }),
      };
      return axios(
        `${context.qmsToolApiUrl}functions/computeCriticity`,
        options,
      ).then((response) => response.data);
    } else {
      return Promise.resolve(null);
    }
  } catch (error) {
    return Promise.reject(error);
  }
};

const computeCriticity = (context, event) => {
  try {
    const severity = context?.changeSet?.severity;
    const probability = context?.changeSet?.probability;
    const consequence_type = context?.changeSet?.consequence?.name;

    if (severity && probability && consequence_type) {
      const options = {
        ...defaultPostOptions,
        headers: {
          ...defaultPostOptions.headers,
          Authorization: `Bearer ${context.authentication.token}`,
        },
        data: JSON.stringify({
          severity: severity.associated_value,
          probability: probability.associated_value,
          consequence_type: consequence_type,
        }),
      };
      return axios(
        `${context.qmsToolApiUrl}functions/computeCriticity`,
        options,
      ).then((response) => response.data);
    } else {
      return Promise.resolve(null);
    }
  } catch (error) {
    return Promise.reject(error);
  }
};

const requalifyEvent = async (context, event) => {
  try {
    const options = {
      ...defaultPostOptions,
      headers: {
        ...defaultPostOptions.headers,
        Authorization: `Bearer ${context.authentication.token}`,
      },
      data: JSON.stringify({
        requalification_reason: context.changeSet.requalification_reason,
        notify_report_submitter: context.changeSet.notify_report_submitter,
        event: {
          consequence_type: context?.changeSet?.consequence?.name,
          severity: context?.changeSet?.severity?.associated_value,
          probability: context?.changeSet?.probability?.associated_value,
          criticity_type: context?.changeSet?.criticity?.name,
        },
      }),
    };
    return axios(
      `${context.qmsToolApiUrl}reports/${context.reportId}/requalify`,
      options,
    ).then((response) => response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export { computeCriticityFromConsequence, computeCriticity, requalifyEvent };
