import StepsContainer from "../../../container";
import { useRequalifyReportMachine } from "../../../../../context/RequalifyReportMachineContext";
import { useTranslation } from "react-i18next";

export default function Step3() {
  const [state, send] = useRequalifyReportMachine();
  const { t } = useTranslation();

  return (
    <StepsContainer>
      <div>
        <label
          htmlFor="requalification_reason"
          className="block text-sm font-medium leading-6 text-gray-600"
        >
          {t("requalifyEvent.view.label.reason")}
        </label>
        <div className="mt-2">
          <input
            autoFocus
            type="text"
            name="requalification_reason"
            id="requalification_reason"
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            onChange={(e) => {
              send("REASON_CHANGED", {
                data: e.target.value,
              });
            }}
            value={state.context.changeSet.requalification_reason || ""}
            tabIndex={1}
          />
        </div>

        <div className="mt-6">
          <div className="mt-2 flex items-start gap-2">
            <input
              id="notify_report_submitter"
              name="notify_report_submitter"
              defaultValue={
                state.context.changeSet.notify_report_submitter || false
              }
              type="checkbox"
              defaultChecked={
                state.context.changeSet.notify_report_submitter || false
              }
              className="mt-1 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
              onClick={(event) => {
                send("NOTIFY_REPORT_SUBMITTER_CHANGED", {
                  data: event.target.value,
                });
              }}
            />
            <label
              htmlFor="notify_report_submitter"
              className="block text-sm font-medium leading-6 text-gray-600"
            >
              {t("requalifyEvent.view.label.notifyReportSubmitter")}
            </label>
          </div>
        </div>
      </div>
    </StepsContainer>
  );
}
